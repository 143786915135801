
/* Library entry-point */

//import components from './components/index.js';
import dialogs from './dialogs/index.js';
//import pages from './pages/index.js';
//import caches from './caches/index.js';

/*
components.forEach(function (component) {
	if (!component.module_type || component.module_type == 'ko')
	{
		console.log('registering component', component);
		ko.components.register(component.name, {
			template: component.template,
			viewModel: component.viewModel
		});
	}
});
*/

dialogs.forEach(function (dialog) {
	window.Grape.dialogs[dialog.name] = {
		pageClass: dialog.dialog_class,
		template: dialog.template
	};

});

/*
pages.forEach(function (page) {
	console.log('Registering page', page);
	window.Grape.route(page.route, page);
});

caches.forEach(function(cache) {
});
*/


