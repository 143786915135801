/**
 * FUNCTION: Generate HTML containing form elements based on JSON passed in bindings request parameters
 * Builds a form with input 
 * -------------------------------------------- 
 *  
 * EXAMPLE: Request Parameters that can be sent
 * --------------------------------------------
 * fields: 
 * [
 *  {
 *  	name: 
 * 	type: 
 * 		'text', 
 *		'integer', 
 *		'list', 
 *		'date', 
 *		'month' 
 *
 *	list: [] 		(optional - used when type is 'list')
 *	list_label_field:	if list contains objects, this field will be displayed
 *	list_value_field:	if list contains objects, this field will be used as the value
 *
 * 	label: 			(optional), 
 *	date_range: { start: date, end: date }		(used when type is 'month' or 'date' to give a range)
 *	month_range: [0, 12]				(used when type is month)
 *  }
 * ]
 * data: {} -- object to use as initial input - any extra fields will be returned as is
 */

import template from './data_input.html';

//-------
//DIALOG: Renders form input controls based on JSON provided
var DataInputDialog = function(bindings) {
	var self = this;
	this.self = self;
	this.fields = [];
	this.bindings = bindings;
	this.name = 'DataInput';
	this.object_to_display = bindings;

	//------------------
	//FUNCTION: First initial function call on dialog load
	this.init = function() {

		var fields = self.bindings.fields;

		//--------
		//LOGIC: Display page title if exists
		if (self.bindings.title)
			$("#span_header").html(self.bindings.title);

		//--------
		//LOGIC: Display sub-heading message
		if (self.bindings.message)
			$("#span_message").html(self.bindings.message);
		else
			$(".grape_dialog_subheading").remove();

		if(self.bindings.width)
			$(".datainput_dialog").css("width", self.bindings.width);

		//--------
		//LOGIC: Button Save Text
		if (self.bindings.button_save_text)
			$("#btnSaveText").html(self.bindings.button_save_text);

		//--------
		//LOGIC: Header icon
		if (self.bindings.header_icon)
			$("#header_icon").html('<span class="fa '+ self.bindings.header_icon +' fa-2x grape_dialogheader_circle_icon"></span>  ');
		else
			$("#header_icon").html('<span class="fa fa-align-left fa-2x grape_dialogheader_circle_icon"></span>  ');

		var data = self.bindings.data || {};

		fields.forEach(function(f) {
			//Apply default value if found in data
			if (f.default_value == '' && data[f.name])
				f.default_value = data[f.name];
		});
		
		//LOGIC: Store fields array built above into global fields array
		self.fields = fields;

		Grape.render_fields("#div_data_input_fields", fields);
	};


	//----------
	//EVENT: Close Dialog
	this.btnclose_click = function() { 
		self.close(null); 
	}

	//----------
	//EVENT: Save Parameters utilsing jQuery CSS Selectore on name attribute
	this.btnsave_click = function() {
		var obj = Grape.retrieve_fields('#div_data_input_fields', self.fields);
		
		obj = _.extend(self.bindings.data || {}, obj);

		//DISPLAY: Close dialog
		self.close(obj);
	};
}

export default {
	name: 'DataInput',
	dialog_class: DataInputDialog,
	template: template
};


